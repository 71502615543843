import React from 'react';

enum WikiIDs {
  id_wowgpreconcile = 'wowgpreconcile',
  id_qualitysupplierapproval = 'qualitysupplierapproval',
  id_glowners = 'glowners',
  id_gldescriptions = 'gldescriptions',
  id_updateglstatus = 'updateglstatus',
  id_glaabrands = 'glaabrands',
  id_uploadformula = 'uploadformula',
  id_mfgfilter = 'mfgfilter',
  id_pt = 'pt',
  id_perm = 'perm',
  id_trkset = 'trkset',
  id_sysal = 'sysal',
  id_amreq = 'amreq',
  id_aoreq = 'aoreq',
  id_brandpage = 'brandpage',
  id_amdry = 'amdry',
  id_frc = 'frc',
  id_custprc = 'custprc',
  id_promo = 'promo',
  id_dist = 'dist',
  id_purch = 'purch',
  id_pricingtable = 'pricingtable',
  id_eastonsch = 'eastonsch',
  id_mixingsch = 'mixingsch',
  id_eastonsdlb = 'eastonsdlb',
  id_eastonmdmx = 'eastonmdmx',
  id_eastonferm = 'eastonferm',
  id_eastonmdmxver = 'eastonmdmxver',
  id_eastonfermver = 'eastonfermver',
  id_eastonstorver = 'eastonstorver',
  id_eastonlabver = 'eastonlabver',
  id_eastonekonek = 'eastonekonek',
  id_eastonptbag = 'eastonptbag',
  id_eastonlab = 'eastonlab',
  id_eastonanalyticallab = 'eastonanalyticallab',
  id_eastonstorage = 'eastonstorage',
  id_stockyardsqly = 'stockyardsqly',
  id_eastonqly = 'eastonqly',
  id_orderfrm = 'orderfrm',
  id_splychn = 'splychn',
  id_splychndet = 'splychndet',
  id_ptpage = 'ptpage',
  id_vendors = 'vendors',
  id_checkpricing = 'checkpricing',
  id_invchecklist = 'invchecklist',
  id_mixing = 'mixing',
  id_bagging = 'bagging',
  id_reworks = 'reworks',
  id_stockyardtestinglab = 'stockyardtestinglab',
  id_eastonchecklist = 'eastonchecklist',
  id_productionsetup = 'productionsetup',
  id_stockyardsan = 'stockyardsan',
  id_stockyardfac = 'stockyardfac',
  id_eastonsan = 'eastonsan',
  id_eastonfac = 'eastonfac',
  id_stockyardmaincheck = 'stockyardmaincheck',
  id_eastonmaincheck = 'eastonmaincheck',
  id_stockyardtrackver = 'stockyardtrackver',
  id_freight = 'freight',
  id_times = 'times',
  id_reports = 'reports',
  id_itemrequest = 'itemrequest',
  id_vendordetail = 'vendordetail',
  id_vendorrequest = 'vendorrequest',
  id_wiki = 'wiki',
  id_biozymebucks = 'biozymebucks',
  id_orderfields = 'orderfields',
  id_orderitem = 'orderitem',
  id_maprestriction = 'maprestriction',
  id_vfdrestriction = 'vfdrestriction',
  id_locrestriction = 'locrestriction',
  id_ptshowprice = 'ptshowprice',
  id_availableinventory = 'availableinventory',
  id_availableinventoryorders = 'availableinventoryorders',
  id_ffsamm = 'ffsamm',
  id_ffsites = 'ffsites',
  id_ffpickupvehicles = 'ffpickupvehicles',
  id_ffmaxshipping = 'ffmaxshipping',
  id_ftbasedays = 'ftbasedays',
  id_ftsammbasedays = 'ftsammbasedays',
  id_ftcutoff = 'ftcutoff',
  id_ftcloseddates = 'ftcloseddates',
  id_ftcustomerbasedays = 'ftcustomerbasedays',
  id_ftcategories = 'ftcategories',
  id_ftitems = 'ftitems',
  id_ftproducttypes = 'ftproducttypes',
  id_reqshipcalc = 'reqshipcalc',
  id_reorderpoint = 'reorderpoint',
  id_pricingweight = 'pricingweight',
  id_minpurchamt = 'minpurchamt',
  id_orderdist = 'orderdist',
  id_distributions = 'distributions',
  id_distributiondivisions = 'distributiondivisions',
  id_distributiondivisioncategories = 'distributiondivisioncategories',
  id_orderhold = 'orderhold',
  id_merchsizes = 'merchsizes',
  id_downstream = 'downstream',
  id_shopify_newshopifyorder = 'newshopifyorder',
  id_shopify_getitempricing = 'getitempricing',
  id_shopify_getiteminventory = 'getiteminventory',
}

export default WikiIDs;
